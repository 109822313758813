<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="mb-4 h4 font-weight-bold text-color-green">
          Detail Pemeriksaan {{ this.patientVisitData.checkup_date }}
        </div>
        <div class="card">
          <div class="card-body background-blue">
            <div class="">
              <div class="row">
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Nama
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.name }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Tanggal Lahir
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Usia
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Jenis Kelamin
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.gender }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Cara Pembayaran
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.payment_method }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Dokter
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Doctor.User.name }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        No. RM
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.medical_record_no }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Status
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.status }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 135px; height: 32px; padding-right: 8px"
                      >
                        Waktu Pemeriksaan
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.checkup_time }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-xl-4">
              <div class="card">
                <div class="card-body">
                  <table>
                    <tr>
                      <td
                        style="width: 170px; height: 32px; padding-right: 15px"
                      >
                        Nama Lengkap
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.name }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Jenis Kelamin
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.gender }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Tempat Lahir
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.birth_place }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Tanggal Lahir
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Usia
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        No. Rekam Medis
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.medical_record_no }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        NIK
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.NIK }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Cara Pembayaran
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.payment_method }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        this.patientVisitData.Patient.payment_method ===
                        'Asuransi'
                      "
                    >
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Nama Asuransi
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.insurance_name }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        this.patientVisitData.Patient.payment_method !==
                        'Mandiri'
                      "
                    >
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        No. Asuransi
                      </td>
                      <td
                        style="padding-left: 15px"
                        v-if="
                          this.patientVisitData.Patient.payment_method === 'JKN'
                        "
                      >
                        {{ this.patientVisitData.Patient.bpjs_no }}
                      </td>
                      <td
                        style="padding-left: 15px"
                        v-if="
                          this.patientVisitData.Patient.payment_method ===
                          'Asuransi'
                        "
                      >
                        {{ this.patientVisitData.Patient.insurance_no }}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        No. Telepon Rumah
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.telephone_no"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.telephone_no }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        No. Telepon Selular
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.phone_no"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.phone_no }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Email
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.email"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.email }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Alamat KTP
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.identity_address }}, RT
                        {{ this.patientVisitData.Patient.identity_RT }}, RW
                        {{ this.patientVisitData.Patient.identity_RW }},
                        Kelurahan
                        {{ this.patientVisitData.Patient.identity_village }},
                        Kecamatan
                        {{ this.patientVisitData.Patient.identity_district }},
                        {{ this.patientVisitData.Patient.identity_regency }},
                        {{ this.patientVisitData.Patient.identity_province }},
                        {{ this.patientVisitData.Patient.identity_nation }},
                        {{ this.patientVisitData.Patient.identity_postal_code }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Alamat Domisili
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.domicile_address }}, RT
                        {{ this.patientVisitData.Patient.domicile_RT }}, RW
                        {{ this.patientVisitData.Patient.domicile_RW }},
                        Kelurahan
                        {{ this.patientVisitData.Patient.domicile_village }},
                        Kecamatan
                        {{ this.patientVisitData.Patient.domicile_district }},
                        {{ this.patientVisitData.Patient.domicile_regency }},
                        {{ this.patientVisitData.Patient.domicile_province }},
                        {{ this.patientVisitData.Patient.domicile_nation }},
                        {{ this.patientVisitData.Patient.domicile_postal_code }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Nama Ibu Kandung
                      </td>
                      <td style="padding-left: 15px">
                        {{
                          this.patientVisitData.Patient.biological_mother_name
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Agama
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.religion }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Suku
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.ethnic"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.ethnic }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Bahasa
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.language }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Pendidikan
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.education"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.education }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Pekerjaan
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.job"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.job }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Status Pernikahan
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.martial_status }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-xl-8">
              <div class="card">
                <div class="card-body">
                  <a-tabs :default-active-key="setTab()">
                    <a-tab-pane key="1" tab="Anamnesis">
                      <div class="font-weight-bold">Nama Perawat</div>
                      <div>{{ this.patientVisitData.nurse_name }}</div>
                      <br />
                      <div class="font-weight-bold">Keluhan Utama</div>
                      <div
                        v-for="(keluhan, index) in this.patientVisitData
                          .Anamnesis.ChiefComplaints"
                        :key="index"
                      >
                        <div>• {{ keluhan.keterangan }}</div>
                      </div>
                      <br />
                      <div class="font-weight-bold">Riwayat Penyakit</div>
                      <div>
                        <table class="table">
                          <thead>
                            <th
                              style="
                                width: 200px;
                                height: 32px;
                                padding-right: 15px;
                              "
                            >
                              Kategori
                            </th>
                            <th>Nama Penyakit</th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(riwayat, index) in this.patientVisitData
                                .Anamnesis.IllnessHistories"
                              :key="index"
                            >
                              <td v-if="riwayat.type === 'personal'">
                                Riwayat Penyakit Pribadi
                              </td>
                              <td v-if="riwayat.type === 'family'">
                                Riwayat Penyakit Keluarga
                              </td>
                              <td>{{ riwayat.display }}</td>
                            </tr>
                            <tr
                              v-if="
                                this.patientVisitData.Anamnesis.IllnessHistories
                                  .length === 0
                              "
                            >
                              <td>Tidak ada data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <div class="font-weight-bold">Riwayat Alergi</div>
                      <div>
                        <table class="table">
                          <thead>
                            <th
                              style="
                                width: 200px;
                                height: 32px;
                                padding-right: 15px;
                              "
                            >
                              Jenis Alergi
                            </th>
                            <th>Nama Alergen</th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="allergy in this.patientVisitData.Anamnesis
                                .Allergies"
                              :key="allergy.id"
                            >
                              <!-- <td>{{ allergy.type }}</td> -->
                              <td v-if="allergy.type === 1">Medication</td>
                              <td v-if="allergy.type === 2">Food</td>
                              <td v-if="allergy.type === 3">Environment</td>
                              <td v-if="allergy.type === 4">Biologic</td>
                              <td>{{ allergy.display }}</td>
                            </tr>
                            <tr
                              v-if="
                                this.patientVisitData.Anamnesis.Allergies
                                  .length === 0
                              "
                            >
                              <td>Tidak ada data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br />
                      <div class="font-weight-bold">Riwayat Pengobatan</div>
                      <div>
                        <table class="table">
                          <thead>
                            <th style="height: 32px; padding-right: 15px">
                              Nama Obat
                            </th>
                            <th>Dosis dan Frekuensi</th>
                            <th>Status</th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="medication in this.patientVisitData
                                .Anamnesis.MedicationStatements"
                              :key="medication.id"
                            >
                              <td>{{ medication.name }}</td>
                              <td>{{ medication.dosage }}</td>
                              <td>
                                {{
                                  medicineStatus.find(
                                    (item) => item.value === medication.status,
                                  ).label
                                }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                this.patientVisitData.Anamnesis
                                  .MedicationStatements.length === 0
                              "
                            >
                              <td>Tidak ada data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <a-divider />
                      <table>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Suhu
                          </td>
                          <td style="padding-left: 15px">
                            {{ this.patientVisitData.Anamnesis.suhu }} °C
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Tekanan Darah Sistolik
                          </td>
                          <td style="padding-left: 15px">
                            {{
                              this.patientVisitData.Anamnesis
                                .tekanan_darah_sistolik
                            }}
                            mmHg
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Tekanan Darah Diastolik
                          </td>
                          <td style="padding-left: 15px">
                            {{
                              this.patientVisitData.Anamnesis
                                .tekanan_darah_diastolik
                            }}
                            mmHg
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Saturasi Oksigen
                          </td>
                          <td style="padding-left: 15px">
                            {{
                              this.patientVisitData.Anamnesis.saturasi_oksigen
                            }}%
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Denyut Jantung
                          </td>
                          <td style="padding-left: 15px">
                            {{
                              this.patientVisitData.Anamnesis.nadi
                            }}
                            kali/menit
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Frekuensi Nafas
                          </td>
                          <td style="padding-left: 15px">
                            {{
                              this.patientVisitData.Anamnesis.frekuensi_nafas
                            }}
                            kali/menit
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Tinggi Badan
                          </td>
                          <td
                            v-if="this.patientVisitData.Anamnesis.tinggi_badan"
                            style="padding-left: 15px"
                          >
                            {{
                              this.patientVisitData.Anamnesis.tinggi_badan
                            }}
                            cm
                          </td>
                          <td v-else style="padding-left: 15px">-</td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Berat Badan
                          </td>
                          <td
                            v-if="this.patientVisitData.Anamnesis.berat_badan"
                            style="padding-left: 15px"
                          >
                            {{ this.patientVisitData.Anamnesis.berat_badan }} kg
                          </td>
                          <td v-else style="padding-left: 15px">-</td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 170px;
                              height: 32px;
                              padding-right: 15px;
                            "
                            class="font-weight-bold"
                          >
                            Lingkar Perut
                          </td>
                          <td
                            v-if="this.patientVisitData.Anamnesis.lingkar_perut"
                            style="padding-left: 15px"
                          >
                            {{
                              this.patientVisitData.Anamnesis.lingkar_perut
                            }}
                            cm
                          </td>
                          <td v-else style="padding-left: 15px">-</td>
                        </tr>
                      </table>
                      <a-divider />
                    </a-tab-pane>
                    <a-tab-pane
                      key="2"
                      tab="SOAP"
                      :disabled="this.patientVisitData.status !== 'Selesai'"
                    >
                      <div v-if="this.patientVisitData.status === 'Selesai'">
                        <div class="h5 font-weight-bold">Transkrip</div>
                        <a-textarea
                          v-model="this.patientVisitData.DoctorNote.transcript"
                          readonly
                          :rows="10"
                        ></a-textarea>
                        <br />
                        <a-divider />
                        <div class="h5 font-weight-bold">Catatan SOAP</div>
                        <br />
                        <div class="font-weight-bold SOAP-title-color">
                          Subjective
                        </div>
                        <div
                          v-html="
                            formatText(
                              this.patientVisitData.DoctorNote.subjective,
                            )
                          "
                        ></div>
                        <br />
                        <div class="font-weight-bold SOAP-title-color">
                          Objective
                        </div>
                        <div
                          v-html="
                            formatText(
                              this.patientVisitData.DoctorNote.objective,
                            )
                          "
                        ></div>
                        <br />
                        <div class="font-weight-bold SOAP-title-color">
                          Assessment
                        </div>
                        <div
                          v-html="
                            formatText(
                              this.patientVisitData.DoctorNote.assessment,
                            )
                          "
                        ></div>
                        <div class="font-weight-bold">Diagnosis Primer:</div>
                        <div>
                          •
                          {{
                            this.patientVisitData.DoctorNote.Conditions[0].code
                          }}
                          |
                          {{
                            this.patientVisitData.DoctorNote.Conditions[0].STR
                          }}
                        </div>
                        <div class="font-weight-bold">Diagnosis Sekunder:</div>
                        <div
                          v-for="condition in this.patientVisitData.DoctorNote.Conditions.slice(
                            1,
                          )"
                          :key="condition.code"
                        >
                          • {{ condition.code }} | {{ condition.STR }}
                        </div>
                        <div
                          v-if="
                            this.patientVisitData.DoctorNote.Conditions
                              .length === 1
                          "
                        >
                          -
                        </div>
                        <br />
                        <div class="font-weight-bold SOAP-title-color">
                          Plan
                        </div>
                        <div
                          v-html="
                            formatText(this.patientVisitData.DoctorNote.plan)
                          "
                        ></div>
                        <div class="font-weight-bold">Kode Tindakan:</div>
                        <div
                          v-for="action in this.patientVisitData.DoctorNote
                            .Procedures"
                          :key="action.code"
                        >
                          • {{ action.code }} | {{ action.STR }}
                        </div>
                        <div
                          v-if="
                            this.patientVisitData.DoctorNote.Procedures
                              .length === 0
                          "
                        >
                          -
                        </div>
                        <br />
                        <a-divider />
                        <div class="h5 font-weight-bold">Resep</div>
                        <br />
                        <div class="font-weight-bold SOAP-title-color">
                          Resep
                        </div>
                        <div
                          v-for="(medication, index) in this.patientVisitData
                            .DoctorNote.MedicationRequests"
                          :key="index"
                        >
                          • {{ medication.name }} |
                          {{ medication.quantity }} ({{ medication.dosage }} x
                          {{ medication.frequency }},
                          {{ medication.use_morning === true ? 'Pagi' : '' }}
                          {{ medication.use_noon === true ? 'Siang' : '' }}
                          {{ medication.use_night === true ? 'Malam' : '' }})
                        </div>
                        <br />
                        <div class="font-weight-bold SOAP-title-color">
                          Catatan Resep
                        </div>
                        <div
                          v-if="
                            this.patientVisitData.DoctorNote.medication_note
                          "
                          v-html="
                            formatText(
                              this.patientVisitData.DoctorNote.medication_note,
                            )
                          "
                        ></div>
                        <div v-else>Tidak Ada</div>
                        <br />
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="Penunjang">
                      <div class="h5 font-weight-bold">Penunjang</div>
                      <div v-if="this.patientVisitDocuments.length === 0">
                        Pasien ini belum memiliki dokumen penunjang.
                      </div>
                      <div
                        v-for="file in this.patientVisitDocuments"
                        :key="file.id"
                      >
                        <div>
                          Diunggah oleh {{ file.created_by_name }} pada
                          {{ formatDateTimeFull(file.VisitDocument.createdAt) }}
                        </div>
                        <div v-if="file.media_mime_type !== 'application/pdf'">
                          <div class="image-container">
                            <object
                              :data="file.uri"
                              :type="file.media_mime_type"
                              width="400px"
                              min-height="100%"
                            ></object>
                            <button
                              class="zoom-button"
                              @click="openViewer(file.uri, file)"
                            >
                              <img
                                src="resources/images/zoom-icon.svg"
                                alt="Zoom In"
                                width="20px"
                                height="20px"
                              />
                            </button>
                          </div>
                        </div>
                        <div v-else style="min-width: 50vw">
                          <object
                            :data="file.uri"
                            :type="file.media_mime_type"
                            width="100%"
                            height="700px"
                          ></object>
                        </div>
                        <a :href="file.uri" target="_blank">{{ file.name }}</a>
                        <br /><br />
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPatientVisit,
  getDokumenPenunjang,
} from '@/services/axios/api/kunjungan'
import { getUserProfileFromId } from '@/services/axios/api/auth'
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'

export default {
  data() {
    return {
      patientVisitData: {},
      patientVisitDocuments: [],
      visit_id: '',
      medicineStatus: [
        { label: 'Active', value: 'active' },
        { label: 'Completed', value: 'completed' },
        { label: 'Entered in Error', value: 'entered-in-error' },
        { label: 'Intended', value: 'intended' },
        { label: 'Stopped', value: 'stopped' },
        { label: 'Draft', value: 'draft' },
        { label: 'On Hold', value: 'on-hold' },
        { label: 'Unknown', value: 'unknown' },
        { label: 'Not Taken', value: 'not-taken' },
      ],
    }
  },
  async created() {
    this.visit_id = this.$route.params.id
    await this.getPatientVisitData()
  },
  methods: {
    setTab() {
      const role = window.localStorage.getItem('role')
      if (role === 'perawat') {
        return '1'
      } else if (role === 'dokter') {
        if (this.patientVisitData.status === 'Selesai') {
          return '2'
        } else {
          return '1'
        }
      } else {
        return '1'
      }
    },
    openViewer(fileUri, file) {
      const imageElement = document.createElement('img')
      imageElement.src = fileUri
      imageElement.style.display = 'none'
      document.body.appendChild(imageElement)
      console.log(imageElement, 'image element')
      const viewer = new Viewer(imageElement, {
        inline: false,
        navbar: false,
        title: [
          1,
          (imageData) =>
            `${file.name} (${imageData.naturalWidth} x ${imageData.naturalHeight})`,
        ],
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          play: 1,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1,
        },
        viewed() {
          viewer.zoomTo(0.5)
        },
        hidden() {
          document.body.removeChild(imageElement)
        },
      })
      viewer.show()
    },
    formatText(text) {
      return text.replace(/\n/g, '<br>')
    },
    calculateAge(birthdate) {
      const today = new Date()
      const birthDate = new Date(birthdate)

      const years = today.getFullYear() - birthDate.getFullYear()
      const months = today.getMonth() - birthDate.getMonth()
      const days = today.getDate() - birthDate.getDate()

      let ageYears = years
      let ageMonths = months
      let ageDays = days

      if (ageDays < 0) {
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += prevMonth.getDate()
        ageMonths -= 1
      }

      if (ageMonths < 0) {
        ageMonths += 12
        ageYears -= 1
      }

      return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const date = new Date(dateString)
      return date.toLocaleString('id-ID', options)
    },
    formatDateTime(datestring) {
      const date = new Date(datestring)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
    },
    formatDateTimeFull(datestring) {
      const date = new Date(datestring)
      const day = String(date.getDate()).padStart(2, '0')
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ]
      const month = monthNames[date.getMonth()]
      const year = String(date.getFullYear())
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`
    },
    async getUserName(id) {
      try {
        const response = await getUserProfileFromId(id)
        return response.data.user.name
      } catch (error) {
        console.error('Error fetching user name:', error)
        return 'Unknown'
      }
    },
    async getHistoryDocumentImageUrl(file, visitId) {
      const response = await getDokumenPenunjang(visitId, file.id)
      const url = URL.createObjectURL(response.data)
      return url
    },
    async getPatientVisitData() {
      const response = await getPatientVisit(this.visit_id)
      this.patientVisitData = response.data.visit

      if (
        this.patientVisitData.Anamnesis === null ||
        this.patientVisitData.Anamnesis.length === 0
      ) {
        this.$notification.error({
          message: 'Data Tidak Ditemukan!',
          description: 'Data pemeriksaan belum diisi!',
          duration: 5,
        })
        this.$router.go(-1)
        return
      }

      this.patientVisitData = {
        ...this.patientVisitData,
        age: this.calculateAge(this.patientVisitData.Patient.birth_date),
        birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
        gender:
          this.patientVisitData.Patient.gender === 'Male'
            ? 'Laki-laki'
            : 'Perempuan',
        payment_method:
          this.patientVisitData.Patient.payment_method === 'JKN'
            ? 'JKN (BPJS Kesehatan)'
            : this.patientVisitData.Patient.payment_method,
        checkup_time: this.formatDateTime(this.patientVisitData.checkup_time),
        checkup_date: this.formatDate(this.patientVisitData.checkup_time),
        nurse_name: await this.getUserName(
          this.patientVisitData.attending_nurse_id,
        ),
      }
      // this.patientVisitDocuments = this.patientVisitData.documents
      for (const file in response.data.documents) {
        // this.patientVisitDocuments.push({
        //     name: this.patientVisitData.documents[file].name,
        //     url: this.patientVisitData.documents[file].url,
        // })
        response.data.documents[file].created_by_name = await this.getUserName(
          response.data.documents[file].VisitDocument.created_by,
        )
        response.data.documents[
          file
        ].uri = await this.getHistoryDocumentImageUrl(
          response.data.documents[file],
          this.patientVisitData.id,
        )
      }
      this.patientVisitDocuments = response.data.documents
      // console.log(this.patientVisitData, 'data kunjungan pasien')
      // console.log(this.patientVisitDocuments, 'dokumen kunjungan pasien')
    },
  },
}
</script>

<style scoped>
.text-color-green {
  color: #428a94;
}
.background-blue {
  background-color: #f6feff;
}
.table thead {
  border-bottom: 1px solid #e5e5e5;
}
.table th,
.table td {
  border: none;
  padding: 8px;
}
.SOAP-title-color {
  color: #428a94;
}
.image-container {
  position: relative;
  display: inline-block;
  margin: 0;
}
.zoom-button {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 999px;
}
.zoom-button:hover {
  background-color: rgba(255, 255, 255, 1);
}
</style>
